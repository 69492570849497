




















import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import DisciplinesReportFilter, { IDisciplinesReportFilterModel } from '@/components/reports/DisciplinesReportFilter.vue';
import { apiService } from '@/services/api.service';
import { downloadReportService } from '@/services/download-report.service';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: { DisciplinesReportFilter, ValidationObserver },
})
export default class CreateDisciplinesReportView extends Vue {
  public filterModel: IDisciplinesReportFilterModel = {
    endDate: dayjs().format('YYYY-MM-DD'),
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    langPreferences: ['nl', 'fr']
  }

  public async downloadReport(): Promise<void> {
    const response = await apiService.getDisciplinesReport(this.filterModel as IDisciplinesReportFilterModel);
    downloadReportService.downloadReport(`${this.$t('discipline.disciplines')} ${this.$t('report.report')}`, response);
  }
}
