




















import { Component, Vue } from 'vue-property-decorator';
import EvaluationsReportFilter, {
  IEvaluationsReportFilterModel,
} from '@/components/reports/EvaluationsReportFilter.vue';
import { apiService } from '@/services/api.service';
import { downloadReportService } from '@/services/download-report.service';
import dayjs from 'dayjs';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: { EvaluationsReportFilter, ValidationObserver },
})
export default class CreateEvaluationsReportView extends Vue {

  public filterModel: IEvaluationsReportFilterModel = {
    since: 'threeMonths',
  }

  public async downloadReport(): Promise<void> {
    const response = await apiService.getEvaluationsReport({ startDate: this.getSinceAsDate(this.filterModel) });
    downloadReportService.downloadReport(`${this.$t('evaluation.evaluations')} ${this.$t('report.report')}`, response);
  }

  public getSinceAsDate(filter: IEvaluationsReportFilterModel): string {
      switch (filter.since) {
        case 'threeMonths': {
          return dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        }
        case 'sixMonths': {
          return dayjs().subtract(6, 'month').format('YYYY-MM-DD');
        }
      }
      return dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    }
}
