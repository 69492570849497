














































import { Component, VModel, Vue } from 'vue-property-decorator';

export type langPreference = 'nl' | 'fr';

export interface IDisciplinesReportFilterModel {
  startDate: string;
  endDate: string;
  langPreferences: langPreference[]
}

@Component({})
export default class DisciplinesReportFilter extends Vue {
  @VModel()
  public filterModel!: IDisciplinesReportFilterModel;

  public deselectLang(item: langPreference): void {
    this.filterModel.langPreferences = this.filterModel.langPreferences.filter(langPref => langPref !== item);
  }

  public get langPreferencesChoices(): langPreference[] {
    return ['nl', 'fr'];
  }
}
