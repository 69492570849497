




















import { Component, Vue } from 'vue-property-decorator';
import InternReportFilter, { IInternReportFilterModel } from '@/components/reports/InternReportFilter.vue';
import dayjs from 'dayjs';
import { apiService } from '@/services/api.service';
import { downloadReportService } from '@/services/download-report.service';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: { InternReportFilter, ValidationObserver },
})
export default class CreateInternReportView extends Vue {
  public filterModel: IInternReportFilterModel = {
    internId: '',
    internName: '',
    endDate: dayjs().format('YYYY-MM-DD'),
    startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
  };

  public async downloadReport(): Promise<void> {
    const response = await apiService.getInternReport(this.filterModel);
    downloadReportService.downloadReport(`${this.$t('report.interns.internReport')} ${this.filterModel.internName}`, response);
  }
}
