import dayjs from 'dayjs';
import { notificationService } from '@/services/notification.service';

class DownloadReportService {
  public downloadReport(reportName: string, data: BlobPart): void {
    try {
      const link = document.createElement('a');
      link.setAttribute('download', `${reportName} ${dayjs().format('DD-MM-YYYY')}.xlsx`);
      link.href = window.URL.createObjectURL(new Blob([data]));
      document.body.appendChild(link);
      link.click();
    } catch {
      notificationService.showGenericError();
    }
  }
}

export const downloadReportService = new DownloadReportService();
