













































import { Component, VModel, Vue } from 'vue-property-decorator';
import InternAutoCompleteSelectInput from '@/components/form-components/InternAutoCompleteSelectInput.vue';

export interface IInternReportFilterModel {
  startDate: string;
  endDate: string;
  internName: string;
  internId: string;
}

@Component({
  components: { InternAutoCompleteSelectInput },
})
export default class InternReportFilter extends Vue {

  @VModel()
  public filterModel!: IInternReportFilterModel;
}
