












import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { IInternReportFilterModel } from '@/components/reports/InternReportFilter.vue';
import dayjs from 'dayjs';
import { IApiInternship } from '@/store/modules/internship/contracts/internship.api-contract';
import { apiService } from '@/services/api.service';
import { notificationService } from '@/services/notification.service';

export interface IInternsWithInternship {
  id: string;
  displayName: string;
  internship: {
    startDate: string;
    endDate: string | undefined;
  }
}

@Component({})
export default class InternAutoCompleteSelectInput extends Vue {
  @VModel()
  public filterModel!: IInternReportFilterModel;

  @Prop({default: true})
  public readonly canAddNew!: boolean;

  public loading = true;

  public interns: IInternsWithInternship[] = [];

  public async mounted(): Promise<void> {
    try {
      this.loading = true;
      this.interns = this.mapToInterns(await apiService.getInternships());
    } catch {
      notificationService.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  public updateFilterModel(internId: string): void {
    const intern = this.interns.find(intern => intern.id === internId);
    const startDate = intern?.internship.startDate;
    const endDate = intern?.internship.endDate
    this.filterModel.internId = internId;
    this.filterModel.internName = intern?.displayName ? intern.displayName : '';
    this.filterModel.startDate = startDate ? startDate : dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    this.filterModel.endDate = endDate ? endDate : dayjs().format('YYYY-MM-DD');
  }

  public mapToInterns(internships: IApiInternship[]): IInternsWithInternship[] {
    return internships.map(internship => ({
      id: internship.intern.id,
      displayName: `${internship.intern.firstName} ${internship.intern.lastName}`,
      internship: {
        startDate: internship.startDate,
        endDate: internship.endDate
      }
    }))
  }
}
