





















import { Component, VModel, Vue } from 'vue-property-decorator';

export interface IEvaluationsReportFilterModel  {
  since: periodOptions;
}

export type periodOptions = 'threeMonths' | 'sixMonths' | 'oneYear';

@Component({})
export default class EvaluationsReportFilter extends Vue {
  @VModel()
  public filterModel!: IEvaluationsReportFilterModel;

  public get periodOptions(): periodOptions[] {
    return ['threeMonths', 'sixMonths', 'oneYear'];
  }

}
