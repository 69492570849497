import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"lighten-4 grey pa-3",attrs:{"outlined":"","rounded":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-field__header"},[_vm._v(" "+_vm._s(_vm.$t('assignment.period'))+" ")]),_c(VRow,[_c(VCol,[_c('itaa-date-input',{attrs:{"label":_vm.$t('assignment.startDate'),"required":true,"future-selection-allowed":false},model:{value:(_vm.filterModel.startDate),callback:function ($$v) {_vm.$set(_vm.filterModel, "startDate", $$v)},expression:"filterModel.startDate"}}),_c('div',{staticClass:"text-field__header"},[_vm._v(" "+_vm._s(_vm.$t('report.disciplines.langPreferences'))+" ")]),_c('itaa-select-input',{attrs:{"required":true,"multiple":"","label":_vm.$t('report.disciplines.language'),"items":_vm.langPreferencesChoices},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("report.disciplines.lang." + item)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"close":""},on:{"click:close":function($event){return _vm.deselectLang(item)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(("report.disciplines.lang." + item))))])])]}}]),model:{value:(_vm.filterModel.langPreferences),callback:function ($$v) {_vm.$set(_vm.filterModel, "langPreferences", $$v)},expression:"filterModel.langPreferences"}})],1),_c(VCol,[_c('itaa-date-input',{attrs:{"label":_vm.$t('assignment.endDate'),"required":true,"future-selection-allowed":false},model:{value:(_vm.filterModel.endDate),callback:function ($$v) {_vm.$set(_vm.filterModel, "endDate", $$v)},expression:"filterModel.endDate"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }